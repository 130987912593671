var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tw-pt-32 tw-pb-40 col-header", style: _vm.styleObject },
      [
        _c(
          "div",
          { staticClass: "tw-container tw-mx-auto col-header-container" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "form",
              {
                staticClass:
                  "tw-flex tw-flex-col tw-w-10/12 tw-mx-auto lg:tw-flex-row lg:tw-flex-no-wrap"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-flex tw-flex-col tw-w-full tw-mb-6 lg:tw-w-1/3 lg:tw-mb-0 lg:tw-mr-5 moving-from"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "tw-block tw-mb-2 label-moving-from",
                        attrs: { for: "moving-from" }
                      },
                      [_vm._v("Moving from:")]
                    ),
                    _vm._v(" "),
                    Object.keys(_vm.fromPlaces).length
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.fromPlaceId,
                                expression: "fromPlaceId"
                              }
                            ],
                            staticClass:
                              "tw-relative tw-w-full tw-px-5 tw-border-none select-dropdown",
                            attrs: {
                              id: "moving-from",
                              name: "fromPlaceId",
                              required: ""
                            },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.fromPlaceId = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: { selected: "", disabled: "", value: "" }
                              },
                              [_vm._v("Select City")]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.fromPlaces.dataSet.Table, function(
                              place,
                              index
                            ) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  domProps: { value: place.Place_Id }
                                },
                                [
                                  _vm._v(
                                    _vm._s(place.State) +
                                      " - " +
                                      _vm._s(place.Place_Name)
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-flex tw-flex-col tw-w-full tw-mb-6 lg:tw-w-1/3 lg:tw-mb-0 lg:tw-mr-5 moving-to"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "tw-block tw-mb-2 label-moving-to",
                        attrs: { for: "moving-to" }
                      },
                      [_vm._v("Moving to:")]
                    ),
                    _vm._v(" "),
                    Object.keys(_vm.toPlaces).length
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.toPlaceId,
                                expression: "toPlaceId"
                              }
                            ],
                            staticClass:
                              "tw-relative tw-w-full tw-px-5 tw-border-none select-dropdown",
                            attrs: { name: "toPlaceId", id: "moving-to" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.toPlaceId = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          [
                            Object.keys(_vm.toPlaces).length > 1
                              ? _c("option", [_vm._v("Select City")])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.toPlaces, function(place, index) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  domProps: { value: place.value }
                                },
                                [_vm._v(_vm._s(place.label))]
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-flex tw-flex-col tw-w-full tw-mb-10 lg:tw-w-1/3 lg:tw-mb-0 lg:tw-mr-5 current-salary"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "tw-block tw-mb-2 label-current-salary",
                        attrs: { for: "current-salary" }
                      },
                      [_vm._v("My current salary:")]
                    ),
                    _vm._v(" "),
                    _c("salary-field")
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "tw-flex tw-items-center tw-self-center tw-justify-center tw-px-10 lg:tw-self-end col-calculate",
                    class: _vm.isLoading ? "tw-cursor-wait" : null,
                    attrs: {
                      type: "button",
                      disabled:
                        !_vm.fromPlaceId || !_vm.toPlaceId ? "disabled" : null
                    },
                    on: {
                      click: function($event) {
                        return _vm.onSubmit()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.buttonText))]
                )
              ]
            )
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "-mb-16 tw-bg-gray-100 tw-pb-20 col-content-container" },
      [
        !Object.keys(_vm.results).length
          ? _c(
              "div",
              { staticClass: "tw-container tw-mx-auto col-intro-container" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-relative tw-w-11/12 tw-mx-auto gradient-bg md:tw-w-10/12 xl:tw-w-2/3 col-intro-bg"
                  },
                  [
                    _vm.introText
                      ? _c("div", {
                          staticClass:
                            "tw-w-11/12 tw-mx-auto tw-mb-2 md:tw-mb-0 col-intro-copy",
                          domProps: { innerHTML: _vm._s(_vm.introText) }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "js-video-player-wrapper lg:tw-mb-10",
                        attrs: { "data-image": _vm.introImage }
                      },
                      [
                        _c("div", {
                          attrs: {
                            id: "player",
                            "data-plyr-provider": "youtube",
                            "data-plyr-embed-id": _vm.introVideoID
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        Object.keys(_vm.results).length
          ? _c(
              "div",
              {
                ref: "resultsContainer",
                staticClass: "tw-container tw-mx-auto col-results-container"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-relative tw-w-11/12 tw-mx-auto gradient-bg col-results md:tw-w-10/12 xl:tw-w-2/3"
                  },
                  [
                    _c("div", {
                      staticClass:
                        "tw-w-11/12 tw-mx-auto tw-text-center col-results-copy",
                      domProps: { innerHTML: _vm._s(_vm.results.header_text) }
                    })
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.results.categories, function(category, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "tw-relative tw-w-11/12 tw-mx-auto tw-mt-10 tw-shadow-xl tw-bg-white gradient-bg alternate-gradient lg:tw-flex lg:tw-flex-wrap results-wrapper",
                      class: _vm.opened.includes(category.slug)
                        ? "is-open"
                        : null
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "tw-flex tw-w-full tw-p-8 tw-mx-auto tw-cursor-pointer results-table-display",
                          on: {
                            click: function($event) {
                              return _vm.toggle(category.slug)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "flex items-center justify-center tw-mr-5",
                              class: !_vm.opened.includes(category.slug)
                                ? "rotate-180"
                                : null
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "tw-w-5 tw-h-5 tw-fill-current",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 15.663 9.571"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M9.3,7.751,1.859,0,0,1.939,5.58,7.751,0,13.563,1.859,15.5Z",
                                      transform:
                                        "matrix(0.017, -1, 1, 0.017, 0, 9.3)"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", {
                            staticClass: "tw-select-none tw-font-bold",
                            class:
                              category.category_comparisons.type === "less"
                                ? "text-positive"
                                : "text-negative",
                            domProps: {
                              innerHTML: _vm._s(
                                category.category_comparisons.results_title
                              )
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _vm.opened.includes(category.slug)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "tw-w-full lg:tw-flex lg:tw-flex-wrap"
                            },
                            [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "tw-w-full tw-text-sm md:tw-text-base lg:tw-w-2/3 results-table"
                                },
                                [
                                  _c(
                                    "thead",
                                    { staticClass: "results-table-header" },
                                    [
                                      _c(
                                        "tr",
                                        {
                                          staticClass:
                                            "tw-block tw-mb-5 tw-w-3/4 tw-mx-auto lg:tw-w-11/12 lg:tw-mb-3 lg:tw-mr-1 category-text"
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "tw-leading-tight",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                category.category_comparisons
                                                  .results_text
                                              )
                                            }
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "tr",
                                        {
                                          staticClass:
                                            "tw-relative tw-flex-no-wrap tw-w-11/12 tw-pt-5 tw-mx-auto tw-mr-1 tw-hidden lg:tw-flex"
                                        },
                                        [
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "tw-w-1/4 tw-mb-0 tw-font-normal tw-text-left"
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "tw-mb-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(category.name) +
                                                      " Type"
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "tw-w-1/4 tw-mb-0 tw-font-normal tw-text-left"
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "tw-mb-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.fromPlace.Place_Name
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "tw-w-1/4 tw-mb-0 tw-font-normal tw-text-left"
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "tw-mb-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toPlace.Place_Name
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._m(1, true)
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    { staticClass: "results-table-body" },
                                    _vm._l(category.price_comparisons, function(
                                      comparison
                                    ) {
                                      return _c(
                                        "tr",
                                        {
                                          key: comparison.Category_ID,
                                          staticClass:
                                            "tw-relative tw-flex tw-flex-wrap tw-w-3/4 tw-pt-5 tw-pb-8 tw-mx-auto first:tw-pt-0 lg:tw-flex-no-wrap lg:tw-w-11/12 lg:tw-mr-1 row-border"
                                        },
                                        [
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "tw-w-full tw-mb-5 lg:tw-w-1/4 lg:tw-mb-0"
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "row-header lg:tw-hidden"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(category.name) +
                                                      " Type"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("p", {}, [
                                                _vm._v(
                                                  _vm._s(
                                                    comparison.Category_Name
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "tw-w-1/2 tw-mb-5 lg:tw-w-1/4 lg:tw-mb-0"
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "row-header lg:tw-hidden"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.fromPlace.Place_Name
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                { staticClass: "tw-font-bold" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      comparison.FromPlaceCost
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "tw-w-1/2 tw-mb-5 lg:tw-w-1/4 lg:tw-mb-0"
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "row-header lg:tw-hidden"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toPlace.Place_Name
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "tw-font-bold",
                                                  class:
                                                    Number(
                                                      comparison.ToPlaceCost.replace(
                                                        /[^0-9.-]+/g,
                                                        ""
                                                      )
                                                    ) >
                                                    Number(
                                                      comparison.FromPlaceCost.replace(
                                                        /[^0-9.-]+/g,
                                                        ""
                                                      )
                                                    )
                                                      ? "category-text-negative"
                                                      : "category-text-positive"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      comparison.ToPlaceCost
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "tw-w-full tw-mb-5 lg:tw-w-1/4 lg:tw-mb-0"
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "row-header lg:tw-mb-2 lg:tw-hidden"
                                                },
                                                [_vm._v("National Average")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                { staticClass: "tw-font-bold" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      comparison.NationalAverageCost
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              category.image
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-relative tw-w-full lg:tw-w-1/3 results-image-aspect"
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "tw-absolute tw-inset-0 tw-object-cover tw-w-full tw-h-full flex-image",
                                        attrs: {
                                          loading: "lazy",
                                          src: category.image.sizes.large,
                                          alt: category.image.alt
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.disclaimerText
                  ? _c("div", {
                      staticClass:
                        "tw-w-11/12 tw-py-10 tw-px-16 tw-mx-auto tw-border-2 tw-mt-20 col-disclaimer tw-text-sm",
                      domProps: { innerHTML: _vm._s(_vm.disclaimerText) }
                    })
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "tw-w-10/12 tw-mx-auto tw-mb-10 lg:tw-pb-12 col-title" },
      [_c("h1", [_vm._v("Cost of Living Calculator")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "th",
      { staticClass: "tw-w-1/4 tw-mb-0 tw-font-normal tw-text-left" },
      [_c("p", { staticClass: "tw-mb-2" }, [_vm._v("National Average")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }