const axios = require("axios");

export default {
  state: {
    places_data: {},
    is_loading_places_data: false,
    places_data_error: null,

    salary: null,

    results: {},
    is_loading_results: false,
    results_error: null,
  },
  mutations: {
    setPlacesData(state, data) {
      state.places_data = data;
    },
    setIsLoadingPlacesData(state, is_loading) {
      state.is_loading_places_data = is_loading;
    },
    setPlacesError(state, error) {
      state.places_data_error = error;
    },
    setSelectedCity(state, data) {
      state.selected_city = data;
    },
    setSalary(state, data) {
      state.salary = data;
    },
    setIsLoadingResults(state, is_loading) {
      state.is_loading_results = is_loading;
    },
    setResults(state, data) {
      state.results = data;
    },
    setResultsError(state, error) {
      state.results_error = error;
    },
  },
  getters: {
    getPlaceById: (state) => (id) => {
      return state.places_data.dataSet.Table.find(
        (item) => item.Place_Id === id
      );
    },
  },
  actions: {
    requestResults({ commit }, params) {
      commit("setIsLoadingResults", true);

      axios
        .post(
          dciCostOfLivingJS.siteURL + "/wp-json/cost-of-living/v1/results",
          params
        )
        .then((response) => {
          commit("setResults", response.data);
        })
        .catch((error) => {
          console.log(error);

          commit("setResultsError", error);
        })
        .then(() => {
          commit("setIsLoadingResults", false);
        });
    },
    fetchPlacesData(context) {
      context.commit("setIsLoadingPlacesData", true);

      const licenseeGuid = "{5B5A7F21-A4E6-4BD0-96C1-32D9D8960C5F}"; // Api Key
      const apiUrl = "https://api.c2er.org/costofliving/v3.0/api/api"; // Api Url

      axios
        .get(apiUrl + "/GetPlaces", {
          params: {
            licenseeGuid: licenseeGuid,
            indexType: 1,
            placeType: 1,
          },
        })
        .then((response) => {
          // console.log(response);
          context.commit("setPlacesData", response.data);

          context.commit("setIsLoadingPlacesData", false);
        })
        .catch((error) => {
          console.error(error);

          context.commit("setIsLoadingPlacesData", false);
          context.commit("setPlacesError", error);
        });
    },
  },
};
