var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "salary-field" },
    [
      _c("currency-input", {
        ref: "salary",
        staticClass:
          "tw-border-none tw-relative tw-w-full tw-px-5 input-salary",
        attrs: {
          id: "current-salary",
          name: "fromSalary",
          locale: _vm.locale,
          currency: _vm.currency,
          "decimal-length": _vm.decimalLength,
          min: _vm.min,
          max: _vm.max,
          "distraction-free": false
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }