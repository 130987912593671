<template>
  <div>
    <!-- Form Header -->
    <div class="tw-pt-32 tw-pb-40 col-header" :style="styleObject">
      <div class="tw-container tw-mx-auto col-header-container">
        <!-- Title Wrapper -->
        <div class="tw-w-10/12 tw-mx-auto tw-mb-10 lg:tw-pb-12 col-title">
          <h1>Cost of Living Calculator</h1>
        </div>
        <!-- Close Title Wrapper -->

        <!-- Calculator Form -->
        <form class="tw-flex tw-flex-col tw-w-10/12 tw-mx-auto lg:tw-flex-row lg:tw-flex-no-wrap">
          <!-- Moving From -->
          <div
            class="tw-flex tw-flex-col tw-w-full tw-mb-6 lg:tw-w-1/3 lg:tw-mb-0 lg:tw-mr-5 moving-from"
          >
            <label for="moving-from" class="tw-block tw-mb-2 label-moving-from">Moving from:</label>
            <select
              v-if="Object.keys(fromPlaces).length"
              id="moving-from"
              name="fromPlaceId"
              v-model="fromPlaceId"
              required
              class="tw-relative tw-w-full tw-px-5 tw-border-none select-dropdown"
            >
              <option selected disabled value>Select City</option>
              <option
                v-for="(place, index) in fromPlaces.dataSet.Table"
                :key="index"
                :value="place.Place_Id"
              >{{ place.State }} - {{ place.Place_Name }}</option>
            </select>
          </div>

          <!-- Moving To -->
          <div
            class="tw-flex tw-flex-col tw-w-full tw-mb-6 lg:tw-w-1/3 lg:tw-mb-0 lg:tw-mr-5 moving-to"
          >
            <label for="moving-to" class="tw-block tw-mb-2 label-moving-to">Moving to:</label>
            <select
              v-if="Object.keys(toPlaces).length"
              name="toPlaceId"
              v-model="toPlaceId"
              id="moving-to"
              class="tw-relative tw-w-full tw-px-5 tw-border-none select-dropdown"
            >
              <option v-if="Object.keys(toPlaces).length > 1">Select City</option>
              <option
                v-for="(place, index) in toPlaces"
                :key="index"
                :value="place.value"
              >{{ place.label }}</option>
            </select>
          </div>

          <!-- Current Salary -->
          <div
            class="tw-flex tw-flex-col tw-w-full tw-mb-10 lg:tw-w-1/3 lg:tw-mb-0 lg:tw-mr-5 current-salary"
          >
            <label
              for="current-salary"
              class="tw-block tw-mb-2 label-current-salary"
            >My current salary:</label>
            <salary-field></salary-field>
          </div>

          <!-- Calculate Button -->
          <button
            type="button"
            @click="onSubmit()"
            class="tw-flex tw-items-center tw-self-center tw-justify-center tw-px-10 lg:tw-self-end col-calculate"
            :class="isLoading ? 'tw-cursor-wait' : null"
            :disabled="!fromPlaceId || !toPlaceId ? 'disabled' : null"
          >{{ buttonText }}</button>
        </form>
        <!-- Close Calculator Form -->
      </div>
    </div>
    <!-- Close Form Header -->

    <!-- Content Container -->
    <div class="-mb-16 tw-bg-gray-100 tw-pb-20 col-content-container">
      <!-- Intro -->
      <div v-if="!Object.keys(results).length" class="tw-container tw-mx-auto col-intro-container">
        <div
          class="tw-relative tw-w-11/12 tw-mx-auto gradient-bg md:tw-w-10/12 xl:tw-w-2/3 col-intro-bg"
        >
          <!-- Intro Text Wrapper -->
          <div
            v-if="introText"
            class="tw-w-11/12 tw-mx-auto tw-mb-2 md:tw-mb-0 col-intro-copy"
            v-html="introText"
          ></div>
          <!-- Close Text Wraooer -->

          <div class="js-video-player-wrapper lg:tw-mb-10" :data-image="introImage">
            <div id="player" data-plyr-provider="youtube" :data-plyr-embed-id="introVideoID"></div>
          </div>
        </div>
      </div>
      <!-- Close Intro -->

      <!-- Results -->
      <div
        v-if="Object.keys(results).length"
        ref="resultsContainer"
        class="tw-container tw-mx-auto col-results-container"
      >
        <!-- Results Intro -->
        <div
          class="tw-relative tw-w-11/12 tw-mx-auto gradient-bg col-results md:tw-w-10/12 xl:tw-w-2/3"
        >
          <!-- Text Wrapper -->
          <div
            class="tw-w-11/12 tw-mx-auto tw-text-center col-results-copy"
            v-html="results.header_text"
          ></div>
          <!-- Close Text Wrapper -->
        </div>
        <!-- Close Results Intro -->

        <!-- Results Table Wrapper (loop each of these) -->
        <div
          v-for="(category, index) in results.categories"
          :key="index"
          class="tw-relative tw-w-11/12 tw-mx-auto tw-mt-10 tw-shadow-xl tw-bg-white gradient-bg alternate-gradient lg:tw-flex lg:tw-flex-wrap results-wrapper"
          :class="opened.includes(category.slug) ? 'is-open' : null"
        >
          <!-- Header (Click event to show tw-table & Image here) -->
          <div
            class="tw-flex tw-w-full tw-p-8 tw-mx-auto tw-cursor-pointer results-table-display"
            @click="toggle(category.slug)"
          >
            <span
              :class="!opened.includes(category.slug) ? 'rotate-180' : null"
              class="flex items-center justify-center tw-mr-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="tw-w-5 tw-h-5 tw-fill-current"
                viewBox="0 0 15.663 9.571"
              >
                <path
                  d="M9.3,7.751,1.859,0,0,1.939,5.58,7.751,0,13.563,1.859,15.5Z"
                  transform="matrix(0.017, -1, 1, 0.017, 0, 9.3)"
                />
              </svg>
            </span>
            <p
              class="tw-select-none tw-font-bold"
              :class="
                  category.category_comparisons.type === 'less'
                    ? 'text-positive'
                    : 'text-negative'
                "
              v-html="category.category_comparisons.results_title"
            ></p>
          </div>
          <!-- Close Header -->

          <!-- Table Flex Row -->
          <div class="tw-w-full lg:tw-flex lg:tw-flex-wrap" v-if="opened.includes(category.slug)">
            <!-- Table (show with click event) -->
            <table class="tw-w-full tw-text-sm md:tw-text-base lg:tw-w-2/3 results-table">
              <thead class="results-table-header">
                <!-- Category Text -->
                <tr
                  class="tw-block tw-mb-5 tw-w-3/4 tw-mx-auto lg:tw-w-11/12 lg:tw-mb-3 lg:tw-mr-1 category-text"
                >
                  <span
                    class="tw-leading-tight"
                    v-html="category.category_comparisons.results_text"
                  ></span>
                </tr>
                <tr
                  class="tw-relative tw-flex-no-wrap tw-w-11/12 tw-pt-5 tw-mx-auto tw-mr-1 tw-hidden lg:tw-flex"
                >
                  <th class="tw-w-1/4 tw-mb-0 tw-font-normal tw-text-left">
                    <p class="tw-mb-2">{{ category.name }} Type</p>
                  </th>
                  <th class="tw-w-1/4 tw-mb-0 tw-font-normal tw-text-left">
                    <p class="tw-mb-2">{{ fromPlace.Place_Name }}</p>
                  </th>
                  <th class="tw-w-1/4 tw-mb-0 tw-font-normal tw-text-left">
                    <p class="tw-mb-2">{{ toPlace.Place_Name }}</p>
                  </th>
                  <th class="tw-w-1/4 tw-mb-0 tw-font-normal tw-text-left">
                    <p class="tw-mb-2">National Average</p>
                  </th>
                </tr>
              </thead>
              <tbody class="results-table-body">
                <tr
                  v-for="comparison in category.price_comparisons"
                  :key="comparison.Category_ID"
                  class="tw-relative tw-flex tw-flex-wrap tw-w-3/4 tw-pt-5 tw-pb-8 tw-mx-auto first:tw-pt-0 lg:tw-flex-no-wrap lg:tw-w-11/12 lg:tw-mr-1 row-border"
                >
                  <td class="tw-w-full tw-mb-5 lg:tw-w-1/4 lg:tw-mb-0">
                    <p class="row-header lg:tw-hidden">{{ category.name }} Type</p>
                    <p class>{{ comparison.Category_Name }}</p>
                  </td>
                  <td class="tw-w-1/2 tw-mb-5 lg:tw-w-1/4 lg:tw-mb-0">
                    <p class="row-header lg:tw-hidden">{{ fromPlace.Place_Name }}</p>
                    <p class="tw-font-bold">{{ comparison.FromPlaceCost }}</p>
                  </td>
                  <td class="tw-w-1/2 tw-mb-5 lg:tw-w-1/4 lg:tw-mb-0">
                    <p class="row-header lg:tw-hidden">{{ toPlace.Place_Name }}</p>
                    <p
                      class="tw-font-bold"
                      :class="
                        Number(comparison.ToPlaceCost.replace(/[^0-9.-]+/g, '')) >
                        Number(comparison.FromPlaceCost.replace(/[^0-9.-]+/g, ''))
                          ? 'category-text-negative'
                          : 'category-text-positive'
                      "
                    >{{ comparison.ToPlaceCost }}</p>
                  </td>
                  <td class="tw-w-full tw-mb-5 lg:tw-w-1/4 lg:tw-mb-0">
                    <p class="row-header lg:tw-mb-2 lg:tw-hidden">National Average</p>
                    <p class="tw-font-bold">{{ comparison.NationalAverageCost }}</p>
                  </td>
                </tr>
              </tbody>
              <!-- <tfoot class="results-table-footer">
                <tr
                  class="tw-flex tw-w-10/12 tw-pt-2 tw-pb-10 tw-mx-auto lg:tw-w-11/12 lg:tw-mr-1 xl:tw-pr-24"
                >
                  <td colspan="4">
                    Do these costs seem low to you? That’s probably because we’re
                    measuring average costs for the larger metro area, not just
                    the city limits.
                  </td>
                </tr>
              </tfoot>-->
            </table>
            <!-- Close Table -->

            <!-- Category Image -->
            <div
              v-if="category.image"
              class="tw-relative tw-w-full lg:tw-w-1/3 results-image-aspect"
            >
              <img
                loading="lazy"
                :src="category.image.sizes.large"
                :alt="category.image.alt"
                class="tw-absolute tw-inset-0 tw-object-cover tw-w-full tw-h-full flex-image"
              />
            </div>
            <!-- Close Category Image -->
          </div>
          <!-- Table Flex Row -->
        </div>
        <!-- Close Results Table Wrapper (End Loop) -->

        <div
          class="tw-w-11/12 tw-py-10 tw-px-16 tw-mx-auto tw-border-2 tw-mt-20 col-disclaimer tw-text-sm"
          v-if="disclaimerText"
          v-html="disclaimerText"
        ></div>
      </div>
      <!-- Close Results -->
    </div>
    <!-- Close Content Container -->
  </div>
</template>

<script>
import SalaryField from "./SalaryField";

export default {
  name: "CostOfLivingCalculator",
  components: {
    SalaryField
  },
  data() {
    return {
      opened: [],
      fromPlaceId: "",
      toPlaceId: ""
    };
  },
  computed: {
    introText() {
      return dciCostOfLivingJS.intro_text;
    },
    introImage() {
      return dciCostOfLivingJS.intro_image;
    },
    introVideoID() {
      return dciCostOfLivingJS.intro_video_id;
    },
    disclaimerText() {
      return dciCostOfLivingJS.disclaimer_text;
    },
    styleObject() {
      return {
        "background-image": `url(${dciCostOfLivingJS.marquee_image}), linear-gradient(rgba(29,180,224, 1), 
      rgba(29,180,224, 1))`,
        "background-blend-mode": "multiply"
      };
    },
    currentBaseSalary() {
      return this.$store.state.salary;
    },
    toPlace() {
      if (this.results) {
        return this.$store.getters.getPlaceById(this.toPlaceId);
      }
    },
    fromPlace() {
      if (this.results) {
        return this.$store.getters.getPlaceById(this.fromPlaceId);
      }
    },
    fromPlaces() {
      return this.$store.state.places_data;
    },
    toPlaces() {
      if (dciCostOfLivingJS.toPlaces) {
        this.toPlaceId = dciCostOfLivingJS.toPlaces[0].value;
      }

      return dciCostOfLivingJS.toPlaces;
    },
    results() {
      return this.$store.state.results;
    },
    isLoading() {
      return this.$store.state.is_loading_results;
    },
    buttonText() {
      if (this.isLoading) {
        return "Loading...";
      } else {
        return "Calculate";
      }
    }
  },
  mounted() {
    this.$store.dispatch("fetchPlacesData");
  },
  watch: {
    results(newValue) {
      console.log(newValue);
      if (newValue.hasOwnProperty("categories")) {
        this.$nextTick(() => {
          this.scrollResults(); // Call scroll method
        });
      }
    }
  },
  methods: {
    toggle(slug) {
      const index = this.opened.indexOf(slug);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(slug);
      }
    },
    scrollResults() {
      const headerElm = document.querySelector("header nav"),
        resultsElm = this.$refs.resultsContainer;

      window.scrollTo({
        top: resultsElm.offsetTop - headerElm.offsetHeight,
        behavior: "smooth"
      });
    },
    onSubmit() {
      this.$store.dispatch("requestResults", {
        fromPlaceId: this.fromPlaceId,
        toPlaceId: this.toPlaceId,
        currentBaseSalary: this.currentBaseSalary
      });
    }
  }
};
</script>
