// Import Vue
import Vue from "vue/dist/vue.common.js";
import VueX from "vuex";
import Store from "./Store";
import WebFont from "webfontloader";
import Plyr from "plyr";

Vue.use(VueX);

import CostOfLivingCalculator from "../vue/CostOfLivingCalculator.vue";

(function() {
  new Vue({
    components: {
      CostOfLivingCalculator,
    },
    store: new VueX.Store(Store),
    el: "#app",
  });

  WebFont.load({
    google: {
      families: ["Roboto:400,500,700,900", "Nunito Sans:400,500,700,900"],
    },
  });

  const player = new Plyr("#player");

  const player_wrapper = document.querySelector(".js-video-player-wrapper");

  if (player_wrapper !== null) {
    if (player_wrapper.dataset.image) {
      setTimeout(() => {
        player.poster = player_wrapper.dataset.image;
      }, 50);
    }
  }
})();
