<template>
  <div class="salary-field">
    <currency-input
      class="tw-border-none tw-relative tw-w-full tw-px-5 input-salary"
      ref="salary"
      id="current-salary"
      name="fromSalary"
      v-model="value"
      :locale="locale"
      :currency="currency"
      :decimal-length="decimalLength"
      :min="min"
      :max="max"
      :distraction-free="false"
      @change="onChange"
    />
  </div>
</template>

<script>
import {
  CurrencyInput,
  CurrencyDirective,
  parseCurrency
} from "vue-currency-input";

export default {
  name: "salary",
  directives: {
    currency: CurrencyDirective
  },
  components: {
    CurrencyInput
  },
  data() {
    return {
      min: 10000,
      max: 200000,
      decimalLength: 0,
      value: 50000,
      currency: "USD",
      locale: "en-US"
    };
  },
  methods: {
    onChange() {
      if (this.value > this.max) {
        this.value = this.max;

        console.error("Salary over max amount allowed.");
      } else if (this.value < this.min) {
        this.value = this.min;

        console.error("Salary under min amount required.");
      }

      this.$store.commit("setSalary", this.value);
    }
  },
  computed: {
    numberValue() {
      return parseCurrency(this.value, this.locale, this.currency);
    }
  },
  created() {
    this.$store.commit("setSalary", this.value);
  }
};
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}
</style>
